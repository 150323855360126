import learning1 from "../images/learning/nauka_ikona1.png";
import learning2 from "../images/learning/nauka_ikona2.png";
import learning3 from "../images/learning/nauka_ikona3.png";

import screen3 from "../images/newProductPage/hungarian/1.webp";
import screen4 from "../images/newProductPage/hungarian/2.webp";
import screen5 from "../images/newProductPage/hungarian/3.webp";
import screen6 from "../images/newProductPage/hungarian/4.webp";
import screen1 from "../images/newProductPage/hungarian/5.webp";
import screen2 from "../images/newProductPage/hungarian/6.webp";
import screen7 from "../images/newProductPage/hungarian/7.webp";

import new_england from "../images/newProductPage/languages/UK.png";
import new_french from "../images/newProductPage/languages/FR.png";
import new_spanish from "../images/newProductPage/languages/ES.png";
import new_niderland from "../images/newProductPage/languages/ND.png";
import new_germany from "../images/newProductPage/languages/DE.png";
import new_italy from "../images/newProductPage/languages/IT.png";
import new_russia from "../images/newProductPage/languages/RU.png";
import new_portugal from "../images/newProductPage/languages/PR.png";
import new_polish from "../images/newProductPage/languages/PL.png";
import new_sweden from "../images/newProductPage/languages/SE.png";
import new_norway from "../images/newProductPage/languages/NO.png";
import new_grece from "../images/newProductPage/languages/GR.png";

import Ildiko from "../images/newProductPage/hungarian/people/Ildiko.png";
import Katalin from "../images/newProductPage/hungarian/people/Katalin.png";
import Istvan from "../images/newProductPage/hungarian/people/Istvan.png";
import Zoltan from "../images/newProductPage/hungarian/people/Zoltan.png";
import Gabor from "../images/newProductPage/hungarian/people/Gabor.png";
import Maria from "../images/newProductPage/hungarian/people/Maria.png";

import dlaczego1 from "../images/newProductPage/why/dlaczego_1.webp";
import dlaczego2 from "../images/newProductPage/why/dlaczego_2.webp";
import dlaczego3 from "../images/newProductPage/why/dlaczego_3.webp";

import zdjecie1 from "../images/newProductPage/mission/women_square.png";
import zdjecie2 from "../images/newProductPage/mission/latop_square.png";
import zdjecie3 from "../images/newProductPage/mission/people_square.png";

export const hungary = {
  navbar: {
    data: [
      { title: "Tanfolyam rendelése", path: "/course" },
      { title: "Naplózás", path: "/start" },
      { title: "Verbalia", path: "/" },
      { title: "Rólunk", path: "/about" },
    ],
  },
  mainPage: {
    product_page: {
      hero: {
        languages: [
          "angolul",
          "spanyolul",
          "németül",
          "hollandul",
          "olaszul",
          "oroszul",
          "lengyelül",
          "franciául",
          "portugálul",
          // "görögül",
          // "svédül",
          // "norvégul",
        ],
        title: "Beszéljen bátrabban,",
        prefix: "magabiztosan",
        subtitle: `Szerezzen nyelvtudást az életszerű szituációkon alapuló
        nyelvtanfolyamunkon!`,
        buttonText: "Rendeljen most!",
      },
      labelInfo: {
        number: "9",
        numberDescription: "nyelv",
        itemTwo: "14 ezer értékelés",
        itemThree: {
          top: "Több mint 200 ezer",
          bottom: "tanuló",
        },
      },
      learning: {
        title: "Hogyan néz ki a tanfolyam?",
        config: [
          {
            category: "Tanulj nyelvet a legegyszerűbben",
            description:
              "Leckék százai valós beszélgetésekkor használt nyelvi példákkalGyakorlati kifejezések, amelyeket native speakerek által tervezett kurzusokon tanítanakA gyakorlatok hatékonyan tanítják az olvasási, hallgatási és beszédkészségeket",
          },
          {
            category: "Gyakorlati leckék",
            description:
              "A tanulás minden szinten több tucat leckéből áll. Ha nem tudod, milyen szinten vagy – kezdd az alapokkal. Ismerj meg köszöntéseket, a hét napjait, hogyan rendelhetsz ételt egy étteremben. Minden lecke a mindennapi életből tartalmaz példákat. ",
          },
          {
            category: "Feladatok",
            description:
              "Függetlenül attól, hogy napi szinten szeretsz-e keresztrejtvényeket fejteni, vagy éppen ellenkezőleg,inkább kerülöd az ilyen tevékenységet, tagadhatatlan, hogy ezek a fejtörők kiválóan fejlesztik a memóriát, és lehetővé teszik a tanult anyagok megismétlését.",
          },
          {
            category: "Szavak, mondások és kifejezések",
            description:
              "Minden leckét gondosan kiválogatott képek kísérnek, amelyek a tanulási folyamatot intuitívvá és kontextuálissá teszik. A leggyakrabban használt témák a mindennapi életből és az utazásokból származó szókincs széles skáláját ölelik fel, így ezeken a területeken úgynevezett nyelvi blokád nélkül kommunikálhatsz majd.",
          },
        ],
        photoData: [
          screen3,
          screen4,
          screen5,
          screen6,
          screen1,
          screen2,
          screen7,
        ],
      },
      languages: {
        languages: [
          { image: new_england, lang: "Angličtina" },
          { image: new_french, lang: "Francúzština" },
          { image: new_spanish, lang: "Španielčina" },
          { image: new_niderland, lang: "Holandčina" },
          { image: new_germany, lang: "Nemčina" },
          { image: new_italy, lang: "Taliančina" },
          { image: new_russia, lang: "Ruština" },
          { image: new_portugal, lang: "Portugalčina" },
          { image: new_polish, lang: "Poľština" },
          // { image: new_grece, lang: "Görög" },
          // { image: new_sweden, lang: "Svéd" },
          // { image: new_norway, lang: "Norvég" },
        ],
        title: "Elérhető nyelvek:",
        description: ` Készülj fel a következő utazásra, adj hozzá nyelvi ismereteket
        önéletrajzodhoz, vagy mélyedj el egy új kultúrában: fedezd fel a
        Verbalia leckék széles gyűjteményét, és tanuld meg, amire szükséged van
        a beszélgetés elkezdéséhez, tapasztalati szinttől függetlenül.`,
      },
      success: {
        title: "Sikertörténetek",
        subtitle: `  Tekintsd meg, hogy mások hogyan beszélnek folyékonyan a Verbalia
        használatával`,
        config: [
          {
            title: "Gyakorlati leckék",
            name: "Ildikó",
            comment: `Mindegy, hogy kezdő vagy, aki most kezdi megtanulni az alapokat, vagy haladó, aki bővíteni szeretné szókincsét, a Verbalia mindenki számára kínál valamit. Az egyik dolog, amit szeretek ezen a platformon, az a leckék sokfélesége, az étteremben való étkezéstől a taxihívásig, így a tanulási élmény valósághű és gyakorlatias. Ezenkívül a gyakorlati kifejezések és a helyes kiejtést bemutató felvételek óriási előnyt képeznek. `,
            photo: Ildiko,
          },
          {
            title: "Gyakorlati leckék",
            name: "Katalin",
            comment: `Imádom, ahogy a Verbalia leckéket valós helyzetekre szabják, így könnyen alkalmazhatom a tanultakat a valóságban. 3 hónap tanulás után új állást ajánlottak!`,
            photo: Katalin,
          },
          {
            title: "Érdekes platform",
            name: "István",
            comment: `A Verbalia egy kiváló nyelvtanulási platform. A leckék és gyakorlatok változatossága érdekessé és vonzóvá teszi a tanulási folyamatot. A nyelvtanulásnak köszönhetően az interneten megismerkedtem egy német lánnyal, akivel hamarosan összeházasodunk. Ajánlom
            `,
            photo: Istvan,
          },
          {
            title: "A munkahelyemen használom!",
            name: "Zoltán",
            comment: `Véleményem szerint a Verbalia egy csodálatos platform bárki számára, aki fejleszteni szeretné nyelvtudását. A platformot úgy alakították ki, hogy egy új nyelv tanulását élvezetes élménnyé tegye.`,
            photo: Zoltan,
          },
          {
            title: "Kellemes tanulás",
            name: "Gábor",
            comment: `Már néhány hónapja használom a Verbalia-t, és magabiztosan állíthatom, hogy megváltoztatta az életemet. Előléptettek a munkahelyemen! Mindig is érdekelt egy új nyelv elsajátítása, de soha nem volt rá időm vagy erőforrásom. A Verbalia segítségével a saját tempómban és a saját időbeosztásom szerint tanulhatok.
            `,
            photo: Gabor,
          },
          {
            title: "Néhány hónapja használom.",
            name: "Mária",
            comment: `Már több hónapja használom a Verbalia-ot, és azt kell, hogy mondjam, ez nagy változást hozott számomra. Mindig is érdekelt egy új nyelv elsajátítása, de soha nem volt rá lehetőségem, de a Verbalia olyan egyszerűvé és kényelmessé tette számomra a tanulást. Ma már megértek és kommunikálok különböző kultúrájú emberekkel, ami kiszélesítette a látókörömet és új lehetőségeket nyitott meg előttem.`,
            photo: Maria,
          },
        ],
      },
    },
    course: {
      title: "Hogyan néz ki egy tanfolyam?",
      right:
        "Az Eureka platformon nem találsz unalmas, száraz nyelvtani szabályokat, amelyeket egy nap alatt meg kell tanulnod. Megtanulja a mindennapi életben használt mondatokat és kifejezéseket, majd egyszerű gyakorlatokkal és ismétlésekkel megszilárdítja az emlékezetet. A tanfolyam célja, hogy szórakoztató legyen a tanulás.",
      right2Title: "Valós leckék",
      right2Bottom:
        "A tanulás minden szintje több tucat leckéből áll. Ha nem tudod, milyen szinten vagy – kezdje az alapokkal. Ismerje meg a köszöntést, a hét napjait, érzéseit. Ismerje meg, hogyan rendelhet ételt egy étteremben, és hogyan rendelhet taxit. Minden lecke tartalmaz példákat a mindennapi életből. Minden lecke kicsit más, más-más feladatokból és gyakorlatokból áll, de mindegyik egy egészet alkot – így könnyedén elsajátíthatod a nyelvet. Bármikor elkezdheti a leckét.       ",
      flexDown: [
        {
          title: "Tanulás közben olyan elemekkel fog találkozni, mint például:",
          text: `
          A helyes kiejtést tanító hangfelvételek,
        `,
          text1: "Kifejezések és mondatok a való életből,",
          text2: "Képek, amelyek segítségével vizuális asszociációk révén ",
          text3: "emlékezhet szavakra és kifejezésekre,",
          text4: "...a mnoho ďalších.",
        },
        {
          title: "Feladatok",
          text: `Függetlenül attól, hogy napi szinten szeret keresztrejtvényeket fejteni, vagy éppen ellenkezőleg, inkább kerüli az ilyen tevékenységeket, tagadhatatlan, hogy ezek a fejtörők kiválóan fejlesztik a memóriát, és lehetővé teszik a tanult anyagok megismétlését.`,
        },
        {
          title: "Ismétlések",
          text: `A haladóbb tanulók a folyamatos tanulási módban megismételhetik a szókincset, és minden tantárgyi területen tesztelhetik tudásukat. Tartalmazza az összes elérhető szókincset, minden kategóriából. Egy tanulási algoritmus folyamatosan ellenőrzi a tudásszintet, és módosítja a következő leckéket.`,
        },
      ],
    },
    learningWithVerbalia: {
      title: "Tanulás az polyglot nyelvi platformon to: ",
      cards: [
        {
          icon: learning1,
          headline: `Kiváló minőségű
          `,
          text: `
          Az Eureka fejlesztése során egy szakértői csapat az egyik legfontosabb célt tűzte ki célul: hogy módszerünkkel a lehető leggyorsabb legyen az idegen nyelv tanulása (a tanuló életkorától függetlenül!).

            `,
        },
        {
          icon: learning2,
          headline: `Leckék otthon elhagyása nélkül`,
          text: `Az Eureka tanulási alkalmazás használatához nincs szüksége másra, mint telefonjára, számítógépére vagy táblagépére. Az Eureka segítségével bárhol tanulhatsz: sorban, buszon, és mindenekelőtt otthon tanulhatsz anélkül, hogy bárhova menned kell. A fürdőkádban vagy kutyasétáltatás közben tanulsz? Az Eurekával ez lehetséges!
          `,
          text2:
            "Az Eurekával való tanulás sok ingázási időt takarít meg, és könnyedén integrálhatja a tanulást az ütemtervébe!",
        },
        {
          icon: learning3,
          headline: `Rövid, de szisztematikus leckék`,
          text: `A nyelvtanulásnál a legfontosabb a rendszeresség. Ugyanakkor mindannyiunk számára nehéz megtalálni a szükséges időt. Ezt szem előtt tartva az Eurekát úgy tervezték, hogy rövid, de szisztematikus leckékben tanítsák.`,
        },
      ],
    },
    whyVerbalia: {
      title: "Hogyan néz ki a tanfolyam?",
      textUp:
        "Ez egy sokrétű tanulási megközelítésű platform, amely ugyanúgy segít elsajátítani egy új nyelvet, mint az anyanyelvét – természetesen.",
      textBottom:
        "Egy idegen nyelv tanulása megváltoztathatja az életét. Nemcsak ablakot nyit a szakmai és személyes lehetőségekkel teli világra, hanem fejleszti az elmét és növeli az önbizalmat is. Ahogy az olyan technológiák, mint az internet, csökkentik a távolságot, és egy globális közösség tagjaivá válunk, a nyelvtanulás képessége a világgal való interakciónk és sikerünk elengedhetetlen részévé válik.",
      cards: [
        {
          headline: `Tanuljon kifejezéseket és kifejezéseket a mindennapi életből fejlett tanulási technológiánk segítségével.`,
          text: `Az órákat folyamatosan az Ön szintjéhez igazítjuk, így soha nem fogy ki az unalomból vagy a motivációból.
            `,
        },
        {
          headline: `Merüljön el egy idegen nyelvben. Próbáljon meg teljesen megérteni egy új nyelvet a kontextuális tudása alapján.`,
          text: ` A több ezer grafikával és hangfelvétellel úgy fog látni és hallani, mint egy anyanyelvi beszélő.
  
            `,
        },
        {
          headline: `Szerezzen magabiztosságot a beszédben. A nyelvtanulás valódi célja is az, hogy megértsük.`,
          text: `Eureka segítségével nem kell mást tenned, mint ismételni a hallott szókincset – ne félj beszélni! Rendszerünk megjavítja.
  
            `,
        },
        {
          headline: `Ne hagyd, hogy a szavak kikerüljenek az emlékezetedből. Tanuljon különféle gyakorlatokon.`,
          text: `Gondosan megterveztük az alkalmazás elemeit, hogy elméje soha ne unatkozzon. Folyamatosan készülünk, és új kihívásokkal bővítjük az Eurekát, hogy szórakoztatóvá tegyük a tanulást.
  
            `,
        },
      ],
    },
  },
  aboutUs: {
    hero: {
      title: "Küldetésünk",
      description: `Hiszünk abban, hogy a nyelv hidat képez a kultúrák között, küldetésünk pedig a kommunikációs akadályok leküzdése és az emberek összehozása a nyelvoktatáson keresztül.`,
    },
    why: {
      title: "Miért mi?",
      config: [
        {
          title: "Innovatív oktatási módszerek",
          image: dlaczego1,
          description:
            "Kifinomult és gyakorlatban tesztelt tanulási algoritmusunk a tananyagokat a jelenlegi tudásszintjéhez igazítja és a hatékony tanítás útján bővíti azt.",
        },
        {
          title: "Nyelvek széles választéka",
          image: dlaczego2,
          description:
            "A világ legnépszerűbb nyelveit tanulhatja a tanfolyamainkon. Ez lehetővé teszi az igényeinek megfelelő nyelv kiválasztását.",
        },
        {
          title: "Oktatás szórakoztató módon",
          image: dlaczego3,
          description:
            "Platformunk szórakoztató és élvezetes élménnyé teszi a nyelvtanulást, így Ön is motivált lesz a nyelvtanulásra.",
        },
      ],
    },
    mission: {
      config: [
        {
          title: "Személyre szabás és hozzáférés",
          description:
            "A hatékony gépi tanulási algoritmusunk folyamatosan azon dolgozik, hogy minden egyes személy számára megfelelő szintű tananyagot biztosítson. Az Verbalia lehetővé teszi a tanulók számára, hogy önállóan fedezzenek fel nyelvi szabályszerűségeket görcsösség nélkül, hasonlóan, ahogy gyermekkorban tanulja az anyanyelvét.",
          image: zdjecie1,
        },
        {
          title: "A technológia és a tudomány kombinációja",
          description:
            "Az Verbalia a technológia és a tudomány merész kombinációját használja, hogy cselekvésre ösztönözze az elmét. Számtalan szó- és kifejezéslista ismétlése unalmas lehet és az agya nem képes azt feldolgozni. A mi módszerünk azonban izgalmas és hatékony, amely arra ösztönzi, hogy a megszerzett tudást meghatározott pillanatokban különböző módon használja fel. Az agya értékelni fogja ezt a megoldást!",
          image: zdjecie2,
        },
        {
          title: "Közösség és értékek",
          description:
            "A nyelvek segítik az embereket, hogy jobban megismerjék egymást. A nyelvtanulás elősegíti a megértést. Az Verbalia közösség a világ minden tájáról összehozza az embereket. Ambiciózusok vagyunk és mindig feszegetjük a határokat. Hiszünk abban, hogy a nyelvtanulás szórakoztató is lehet és nagy kíváncsisággal fedezünk fel új kultúrákat a nyelven keresztül.",
          image: zdjecie3,
        },
      ],
    },
  },
  formSender: {
    formControll: {
      pageLink: "https://nyelvfejlesztesi-kozpont.eu/",
      title: "Érdekli a tanfolyam? ",
      title1: "Szeretné, hogy több információt?",
      subtitle: "Hagyja meg a telefonszámát - visszahívjuk. ",
      subtitle1:
        "Ha már rendelkezik fiókkal, kérjük, töltse ki az alábbi mezőket:",
      button1: "Név és vezetéknév",
      button2: "Telefonszám",
      button3: "E-mail",
      buttonSub: "Küldje el a",
      checkOne:
        "Az űrlap elküldésével elfogadom a feltételeket, és kijelentem, hogy elolvastam a következőket",
      checkOneTwo: "a",
      checkTwo:
        "Hozzájárulok, hogy marketing célokra felvegyék velem a kapcsolatot",
      textOne: "Tájékoztatjuk, hogy",
      textTwo:
        "Az Ön személyes adatainak kezelője az EOU Sp. z o.o., társaság, székhelye Varsó, Młynarska 42 / 115, 01-171, bejegyezve a varsói kerületi bíróság által vezetett vállalkozói nyilvántartásba KRS5273057993, REGON: 525396900, NIP5273057993. Az Ön személyes adatait a kapcsolatfelvételi űrlapon történő megkeresésének feldolgozásával vagy a megkötött szerződés teljesítésével kapcsolatban dolgozzuk fel. Azzal, hogy kapcsolatba lép velünk, Ön önkéntesen hozzájárul személyes adatainak a fenti célokból történő feldolgozásához. Az Ön adatainak feldolgozásával kapcsolatban Önnek joga van a hozzáféréshez, a helyesbítéshez, a törléshez vagy a feldolgozás korlátozásához, a feldolgozás elleni tiltakozáshoz és az adathordozhatósághoz. Ön jogosult arra is, hogy hozzájárulását bármikor visszavonja, de a hozzájárulás visszavonása nem érinti a visszavonás előtti hozzájárulás alapján végzett adatkezelés jogszerűségét. Önnek joga van ahhoz is, hogy panaszt tegyen a felügyeleti hatóságnál. Az adatok megadása természetesen önkéntes, de szükséges lehet egy megkeresés megválaszolásához vagy egy szerződés teljesítéséhez. A személyes adatokat a fenti célokhoz szükséges ideig tároljuk, de nem az általános jog szerinti követelések elévülési idejéig. Kérjük, figyelmesen olvassa el az adatvédelmi szabályzatot",
      buttonMore: "Olvass tovább",
      buttonLess: "Olvass kevesebbet",
      subOne: "feltételek",
      subTwo: "és adatvédelmi szabályok.",
      buttonTXT: "Köszönöm",
      rulesLink: "/Regulations_HU.pdf",
      privacyLink: "/Privacy_HU.pdf",
      product: "eh",
      market: "hu",
      send_to_url: "https://crm.europa-edu.eu/api/v1/cms/create_order",
      utm_source: "Platform_eLingo",
      product_variant: "hu-en",
      server_link: "https://cms.europa-assets.eu/integration/lead-from-landing",
      currency: "huf",
      price: 29000,
    },
  },
  footer: {
    privacy: "Adatvédelem",
    privacyLink: "/Privacy_HU.pdf",
    rules: "Szolgáltatási feltételek",
    rulesLink: "/Regulations_HU.pdf",
  },
};
