import Store from '../../../../../../Store'
import cs from './images/cs.jpg'
import hr from './images/hr.png'

export const mockImages = {
    cs,
    hr
}

export const getMockImage = () => {
    const short = Store.getUserPlatformLangShort();
    return mockImages[short]
}