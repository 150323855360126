import Store from "../../../Store";
import "./HomePage.css";
import woman from "../../../common/assets/woman.png";
import { useEffect, useState } from "react";
import LoginModal from "../../components/Modals/LoginModal/LoginModal";
import HomepageButton from "../../components/Buttons/HomepageButton/HomepageButton";
import RegisterModal from "../../components/Modals/RegisterModal/RegisterModal";
import PasswordRecoveryModal from "../../components/Modals/PasswordRecoveryModal/PasswordRecoveryModal";
import ChangePasswordHomeModal from "../../components/Modals/ChangePasswordHomeModal/ChangePasswordHomeModal";
import usePropState from "../../common/hook/usePropState";
import { useParams } from "react-router";

const CONFIG = {
  cz: {
    title: "Verbalia – Mluv směleji, sebevědoměji",
    description:
      "Zlepši své jazykové dovednosti s praktickými kurzy. Jazykový kurz Verbalia ti pomůže k úspěchu. Připoj se k eLingu a využij jeho výhod!",
  },
  cs: {
    title: "Verbalia – Mluv směleji, sebevědoměji",
    description:
      "Zlepši své jazykové dovednosti s praktickými kurzy. Jazykový kurz Verbalia ti pomůže k úspěchu. Připoj se k eLingu a využij jeho výhod!",
  },
  hu: {
    title: "Verbalia – Beszélj merészebben, magabiztosabban",
    description:
      "Javítsd nyelvi készségeidet gyakorlati tanfolyamokkal. Az Verbalia nyelvi tanfolyam segít sikeressé válni. Csatlakozz az eLingóhoz és profitálj belőle!",
  },
  en: {
    title: "Verbalia – Speak more boldly, confidently",
    description:
      "Boost your language skills with practical courses. The Verbalia language course will help you succeed. Join Verbalia and benefit!",
  },
  sk: {
    title: "Verbalia – Hovor' smelšie, sebavedomejšie",
    description:
      "Zlepšite svoje jazykové zručnosti s praktickými kurzami. Jazykový kurz Verbalia vám pomôže uspieť. Pripojte sa k eLingu a získajte výhody!",
  },
  lt: {
    title: "Verbalia – Kalbėk drąsiau, pasitikėdamas savimi",
    description:
      "Patobulink savo kalbos įgūdžius praktiškuose kursuose. Verbalia kalbų kursas padės siekti sėkmės. Prisijunk prie Verbalia ir pasinaudok privalumais!",
  },
  hr: {
    title: "Verbalia – Govori smjelije, samopouzdanije",
    description:
      "Unaprijedi svoje jezične vještine praktičnim tečajevima. Jezični tečaj Verbalia pomoći će ti uspjeti. Pridruži se eLingu i iskoristi prednosti!",
  },
  ro: {
    title: "Verbalia – Vorbește mai îndrăzneț, mai încrezător",
    description:
      "Îmbunătățește-ți abilitățile lingvistice cu cursuri practice. Cursul de limbă Verbalia te va ajuta să reușești. Alătură-te Verbalia și beneficiază!",
  },
};

function HomePage({
  setError,
  isInitPasswordRecoveryModalOpen,
  isInitChangePasswordModalOpen,
}) {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    usePropState(isInitChangePasswordModalOpen);
  const [isPasswordRecoveryModalOpen, setIsPasswordRecoveryModalOpen] =
    usePropState(isInitPasswordRecoveryModalOpen);

  const { lang } = useParams();

  useEffect(() => {
    document.title = CONFIG[lang]?.title;
    document.getElementsByName("description")[0].content =
      CONFIG[lang]?.description;
  }, [lang]);

  return (
    <div className="home-page">
      {isLoginModalOpen && (
        <LoginModal
          isModalOpen={isLoginModalOpen}
          setIsModalOpen={setIsLoginModalOpen}
          setError={setError}
        />
      )}
      {isRegisterModalOpen && (
        <RegisterModal
          isModalOpen={isRegisterModalOpen}
          setIsModalOpen={setIsRegisterModalOpen}
          setError={setError}
        />
      )}
      {isPasswordRecoveryModalOpen && (
        <PasswordRecoveryModal
          isModalOpen={isPasswordRecoveryModalOpen}
          setIsModalOpen={setIsPasswordRecoveryModalOpen}
          setError={setError}
        />
      )}
      {isChangePasswordModalOpen && (
        <ChangePasswordHomeModal
          isModalOpen={isChangePasswordModalOpen}
          setIsModalOpen={setIsChangePasswordModalOpen}
          setError={setError}
        />
      )}
      <h2 className="home-page__header">
        <span className="home-page__header--bold">
          {Store.getText("homePageSloganFirstLine", true)}
        </span>{" "}
        {Store.getText("homePageSloganSecondLine", true)}
      </h2>
      <div className="home-page__boxes">
        <div className="home-page__box">
          <h3 className="home-page__box-header">
            {Store.getText("login", true)}
          </h3>
          <p className="home-page__box-text">
            {Store.getText("loginBoxDescription", true)}
          </p>
          <HomepageButton
            className="home-page__box-button"
            onClick={() => setIsLoginModalOpen(true)}
          >
            {Store.getText("loginButtonText", true)}
          </HomepageButton>
        </div>

        <div className="home-page__box home-page__box--transparent">
          <h3 className="home-page__box-header">
            {Store.getText("register", true)}
          </h3>
          <p className="home-page__box-text">
            {Store.getText("registerBoxDescription", true)}
          </p>
          <HomepageButton
            className="home-page__box-button"
            onClick={() => setIsRegisterModalOpen(true)}
          >
            {Store.getText("registerButtonText", true)}
          </HomepageButton>
        </div>
      </div>
      <div className="home-page__circle">
        <img src={woman} alt="woman" className="home-page__circle-image" />
      </div>
    </div>
  );
}

export default HomePage;
